/*
 * Globals
 */
/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #222;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: 0.05rem solid #fff;
}

/* borders */
.dotted-border hr {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
  margin: 0px;
}

.simple-border hr {
  border-top: 1px solid #8c8b8b;
  margin: 0px;
}

/*
 * Base structure
 */

html,
body {
  height: 100%;
  background: url("../img/common/decoration/back.jpg") repeat-x 0 0;
}

body {
  display: flex;
  color: #222;
}

.cover-container {
  max-width: 42em;
}


/*
 * Header
 */
.masthead {
  margin-bottom: 2rem;
}

.masthead-brand {
  margin-bottom: 0;
}

.header-id{
  td{
    width: 144px;
    height: 45px;
  }
}

.head-line {
  padding: 0px;
}

.btn-outline-dark:focus {
  color: #343a40;
}

/*
 * content
 */
#contentsDivision{
  /*background: url(../img/common/decoration/bg_foot_grd.png) repeat-x bottom center;*/
  margin: 0 auto;
  max-width: 1170px;

  .contents-box{
    background: #fff;
    border: 1px solid #a9a9a9;
    border-top: 3px solid #a9a9a9;
    box-shadow: 0px 1px 5px 0px rgba(80, 80, 80, 0.4);
    padding: 50px 40px 40px;
    /*margin-top: 28px;*/
    position: relative;
    z-index: 2;

    /* Links */
    .text-white {
      a,
      a:focus,
      a:hover {
        color: #fff;
      }
    }
  }
}

#mainContentsArea{
  .title{
    h2{
      background: rgb(236,236,236);
      background: linear-gradient(90deg, rgba(236,236,236,1) 80%, rgba(0,206,255,1) 100%);
      -webkit-box-shadow: 1px 1px 1px 1px #CCCCCC;
      box-shadow: 1px 1px 1px 1px #CCCCCC;
      font-size: 120%;
      font-weight: bold;
      border-radius: 2px;
      line-height: 20px;
      padding: 23px;
      margin: 0px;
      height: 20px;
      z-index: 99999;
    }
  }
}

.non-border{
  input[type="text"]{
    border:none;
    background-color: #fff !important;
  }
  .form-control:focus,
  button:focus{
    border-color: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.hidden {
  display: none;
}

.js-calendar {
  background-color: #fff !important;
}

.js-calendar-time {
  background-color: #fff !important;
}

/*
 * Footer
 */
#FooterDivision {
  background: url("../img/common/decoration/footer_back.gif") repeat-x;
  padding-top: 5px;
  height: 23px;
  margin-top: 15px;
  text-align: center;
}

.mastfoot {
  color: rgba(255, 255, 255, .5);
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.email-duplicate {
  background-color: #ffe68c;
}

.large-checkbox {
  transform:scale(2.0);
}


#mainContentsArea .title h2{background: linear-gradient(90deg,#ececec 80%,#a0a0a0)!important}

/*# sourceMappingURL=base.css.map*/

.loginid-input {
  height:35px;
}

.password-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  height:35px;
}

.password-wrapper:focus-within {
  color:#212529;
  background-color:#fff;
  border-color:#86b7fe;
  outline:0;
  box-shadow:0 0 0 .25rem rgba(13,110,253,.25)
}

.login-password-wrapper {
  border: 1px solid #808080;
  width: 179px;
  height:30px;
  margin-left:4px;
}

.login-password-wrapper:focus-within {
  color:#212529;
  background-color:#fff;
  border-color:#000000;
  outline:0;
  box-shadow:0 0 0 .05rem #000000
}

.password-input {
  outline: none;
  appearance: none;
  border: none;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
}

.password-input::placeholder {
  font-size: 12px;
}

// edge標準機能 パスワード入力時目のマークが表示されるため、非表示化
::-ms-reveal {
  display: none;
}

